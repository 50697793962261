<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="Beauty Passport"
        :filter="filter"
        placeholder="Search Stamp Card Name"
        @search="handleSearch"
        @sidebar="sidebarFilter"
        :hasDropdown="false"
        routerPath="/stampcard/details/0"
      />

      <div>
        <SideBarFilter
          ref="sidebarFilter"
          :filter="filter"
          :placeholder="'Search Stamp Card Name'"
          @searchAll="getDataByfilter"
          @clearFilter="clearFilter"
        >
          <template v-slot:filter-option>
            <div class="mt-3">
              <div>
                <div class="font-weight-bold mb-2">Valid From</div>
                <div :class="['input-container']">
                  <datetime
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    v-model="filter.valid_from"
                    ref="startDate"
                    placeholder="Please Select Date"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.startDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer color-primary"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-3">
                <div class="font-weight-bold mb-2">Valid To</div>
                <div :class="['input-container']">
                  <datetime
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    v-model="filter.valid_to"
                    placeholder="Please Select Date"
                    ref="endDate"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.endDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer color-primary"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </SideBarFilter>
      </div>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(name)="data">
                <div>
                  <router-link :to="'/stampcard/details/' + data.item.id">
                    <b-button variant="link" class="name-link">
                      {{ data.item.name }}
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(validFrom)="data">
                <div>{{ data.item.validFrom | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.validFrom | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(validTo)="data">
                <div>{{ data.item.validTo | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.validTo | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div
                  :class="[
                    'd-flex justify-content-center',
                    data.item.status == 1 ? 'text-success' : 'text-danger',
                  ]"
                >
                  {{ data.item.status == 1 ? "Active" : "Inactive" }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/stampcard/details/' + data.item.id">
                    <b-button variant="link" class="text-warning px-1 py-0">
                      <font-awesome-icon icon="pencil-alt" title="Edit" />
                    </b-button>
                  </router-link>
                  <b-button
                    :style="
                      data.item.is_redeem === 0
                        ? 'display : block'
                        : 'display : none'
                    "
                    variant="link"
                    class="text-danger px-1 py-0"
                    @click="deleteData(data.item.id)"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      title="Delete"
                      class="main-color"
                    />
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import SideBarFilter from "@/components/SideBarFilter";
import Pagination from "@/components/Pagination";
export default {
  name: "Stampcard",
  components: {
    HeaderPanel,
    OtherLoading,
    SideBarFilter,
    Pagination,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Stampcard Name",
          class: "w-200",
        },
        {
          key: "number_of_stamp",
          class: "w-200",
          label: "Number Of Stamp",
        },
        {
          key: "validFrom",
          label: "Valid From",
          class: "w-200",
        },
        {
          key: "validTo",
          class: "w-200",

          label: "Valid To",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: [],
        valid_from: "01-01-0001 00:00:00",
        valid_to: "01-01-0001 00:00:00",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
      showingTo: 10,
      isLoading: true,
      confirmMsg: "",
      deleteId: 0,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      let payload = { ...this.filter };
      payload.status =
        this.filter.status.length == 1 ? this.filter.status[0] : null;
      this.isBusy = true;
      payload.valid_from =
        payload.valid_from == "" ? "01-01-0001 00:00:00" : payload.valid_from;
      payload.valid_to =
        payload.valid_to == "" ? "01-01-0001 00:00:00" : payload.valid_to;

      await this.$store.dispatch("getStampCardList", payload);
      let data = this.$store.state.stampcard.stampCardList;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.errorAlert();
      }
    },
    getDataByfilter(val) {
      this.filter = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      if (this.filter.valid_from === "") {
        this.filter.valid_from = "01-01-0001 00:00:00";
      }
      if (this.filter.valid_to === "") {
        this.filter.valid_to = "01-01-0001 00:00:00";
      }
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      if (this.filter.valid_from === "") {
        this.filter.valid_from = "01-01-0001 00:00:00";
      }
      if (this.filter.valid_to === "") {
        this.filter.valid_to = "01-01-0001 00:00:00";
      }
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    hideModal() {
      if (this.deleteId == 0) {
        window.location.href = "/";
        ///
      }
    },
    deleteData(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteStampCard(id);
        }
      });
    },
    async deleteStampCard(val) {
      if (val) {
        this.isLoading = true;
        await this.$store.dispatch("deleteStampCard", this.deleteId);
        let data = this.$store.state.stampcard.respDeleteStampCard;
        if (data.result == 1) {
          this.successAlert().then(() => {
            this.getList();
          });
        } else {
          this.isLoading = false;
          this.errorAlert();
        }
      }
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
        valid_from: "01-01-0001 00:00:00",
        valid_to: "01-01-0001 00:00:00",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  padding-top: 42.9%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.text-success {
  color: #2eb85c;
}
.text-danger {
  color: red;
}
</style>
